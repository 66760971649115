import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Col, message, Row, Select, Skeleton, Space } from 'antd';
import { useGet, usePut, useSave, RLeanState } from '@rlean/core';
import { getValue, deepCopy } from '@rlean/utils';
import { strings, pages } from 'config';
import { useActivePage } from 'lib/hooks';
import { EmployeePortalMessages } from 'lib/entities';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { parse } from 'query-string';
import NotificationDetail from './NotificationDetail';
import NotificationsList from './NotificationsList';
import { isAnyStateLoading } from 'lib/helpers';
import { EmployeePortalMessagesType } from 'lib/entities/EmployeePortalMessages';
import * as entities from 'lib/entities';

const NOTIFICATIONS_PARTS = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

enum NOTIFICATIONS_BOOLEAN_ATTRIBUTES {
  DELIVERED = 'delivered',
  ACKNOWLEDGED = 'acknowledged',
  INACTIVEIND = 'inactiveInd',
}

const Notifications = (props: RouteComponentProps) => {
  const [employeePortalMessages, userDescription] = RLeanState<
    typeof entities
  >().select(({ state }) => [
    state.employeePortalMessages,
    state.userDescription,
  ]);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState<
    EmployeePortalMessagesType | undefined
  >(undefined);
  const [partVisible, setPartVisible] = useState(NOTIFICATIONS_PARTS.LIST);
  const [filters, setFilters] = useState<{
    status: 'all' | 'unread' | 'read';
    priority: 'all' | 'priority' | 'no-priority';
  }>({ status: 'all', priority: 'all' });
  const put = usePut();
  const save = useSave();
  const { Option } = Select;
  const location = useLocation();
  useActivePage(pages.NOTIFICATIONS);

  // Check if the URL contains the message id as query parameter
  useEffect(() => {
    let isMounted = true;
    const { id } = parse(location.search) as any;
    if (isMounted) {
      if (id && !dataIsLoading && employeePortalMessages.data) {
        const msg = employeePortalMessages.data.find(
          (message) => message.id === parseInt(id)
        );
        setSelectedMessage(msg);
      } else {
        setSelectedMessage(undefined);
        setPartVisible(NOTIFICATIONS_PARTS.LIST);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [location, dataIsLoading, employeePortalMessages]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setDataIsLoading(
        isAnyStateLoading(employeePortalMessages, userDescription)
      );
    }
    return () => {
      isMounted = false;
    };
  }, [employeePortalMessages, userDescription]);

  const employeeId = getValue(userDescription, 'data.employeeId', null);
  useGet({ entity: EmployeePortalMessages, params: { employeeId } });

  const onFilterChange = (key: keyof typeof filters) => (value: string) => {
    setFilters((previousState) => ({ ...previousState, [key]: value }));
  };

  const getFilteredNotifications = () => {
    let filteredNotifs = deepCopy(employeePortalMessages?.data || []);
    if (filters) {
      switch (filters.status) {
        case 'unread':
          filteredNotifs = filteredNotifs.filter((notif) => !notif.delivered);
          break;
        case 'read':
          filteredNotifs = filteredNotifs.filter((notif) => notif.delivered);
          break;
        default:
      }

      switch (filters.priority) {
        case 'priority':
          filteredNotifs = filteredNotifs.filter((notif) => notif.priorityInd);
          break;
        case 'no-priority':
          filteredNotifs = filteredNotifs.filter((notif) => !notif.priorityInd);
          break;
        default:
      }
    }

    filteredNotifs.sort((notifA, notifB) => {
      const dateA = new Date(notifA.addedAt);
      const dateB = new Date(notifB.addedAt);
      return dateB.getTime() - dateA.getTime();
    });

    return filteredNotifs;
  };

  const updateMessageBooleanAttributeToTrue = (
    id: number,
    attribute: NOTIFICATIONS_BOOLEAN_ATTRIBUTES
  ) => {
    message.destroy();

    const msg = employeePortalMessages.data.find(
      (message) => message.id === id
    );

    setPartVisible(NOTIFICATIONS_PARTS.DETAIL);

    if (attribute === NOTIFICATIONS_BOOLEAN_ATTRIBUTES.DELIVERED) {
      navigate(`${pages.NOTIFICATIONS.path}?id=${id}`);
    }

    if (msg && !msg[attribute]) {
      message.loading('Updating message...');
      msg[attribute] = true;

      put(
        { entity: EmployeePortalMessages, params: { id }, body: msg },
        (response, error) => {
          message.destroy();

          // if response status is not a 2xx
          if (error || +response.status.toString()[0] !== 2) {
            message.warn(`Couldn't update notification, please try again`);
          } else {
            const newData = deepCopy(
              getValue<typeof employeePortalMessages.data>(
                employeePortalMessages,
                'data',
                []
              )
            ).filter((messages) => messages.id !== id);

            // Save back to the global state if it for readed and acknowledged.
            // If deleted/inactiveInd, then skip saving
            if (
              [
                NOTIFICATIONS_BOOLEAN_ATTRIBUTES.DELIVERED,
                NOTIFICATIONS_BOOLEAN_ATTRIBUTES.ACKNOWLEDGED,
              ].includes(attribute)
            ) {
              save({
                entity: EmployeePortalMessages,
                value: { ...employeePortalMessages, data: [...newData, msg] },
              });
            } else {
              save({
                entity: EmployeePortalMessages,
                value: { ...employeePortalMessages, data: newData },
              });
            }

            message.success('Message update success!');
          }
        }
      );
    }
  };

  const onClickUnreadMessage = (id: number) => {
    updateMessageBooleanAttributeToTrue(
      id,
      NOTIFICATIONS_BOOLEAN_ATTRIBUTES.DELIVERED
    );
  };

  const onAcknowledgeMessage = (id: number) => {
    updateMessageBooleanAttributeToTrue(
      id,
      NOTIFICATIONS_BOOLEAN_ATTRIBUTES.ACKNOWLEDGED
    );
  };

  const onDeleteMessage = (id: number) => {
    updateMessageBooleanAttributeToTrue(
      id,
      NOTIFICATIONS_BOOLEAN_ATTRIBUTES.INACTIVEIND
    );
  };

  if (dataIsLoading) {
    return (
      <Card style={{ marginTop: 25 }} data-testid='notifications-skeleton'>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Card
      className='page-card notifications'
      title={strings.notifications.title}
    >
      <Row gutter={24}>
        <Col
          xs={24}
          lg={9}
          className={`part ${
            partVisible === NOTIFICATIONS_PARTS.LIST ? 'visible' : ''
          }`}
        >
          <Space direction='vertical' style={{ width: '100%' }}>
            <Space>
              <span>Filter by</span>
              <Select
                placeholder='Status'
                style={{ width: 120 }}
                defaultValue={filters.status}
                onChange={onFilterChange('status')}
              >
                <Option value='all'>Any status</Option>
                <Option value='unread'>Only unread</Option>
                <Option value='read'>Only read</Option>
              </Select>
              <span>and</span>
              <Select
                placeholder='Priority'
                style={{ width: 120 }}
                defaultValue={filters.priority}
                onChange={onFilterChange('priority')}
              >
                <Option value='all'>Any priority</Option>
                <Option value='priority'>Only priority</Option>
                <Option value='no-priority'>No priority</Option>
              </Select>
            </Space>
            <NotificationsList
              notifications={getFilteredNotifications()}
              selectedMessageId={selectedMessage?.id}
              onClickUnreadMessage={onClickUnreadMessage}
            />
          </Space>
        </Col>
        <Col
          xs={24}
          lg={15}
          className={`part ${
            partVisible === NOTIFICATIONS_PARTS.DETAIL ? 'visible' : ''
          }`}
        >
          {(employeePortalMessages?.data || []).length === 0 ? null : (
            <Space direction='vertical' style={{ width: '100%' }}>
              <Button
                className='mobile-only keepbuildbox'
                onClick={() => navigate(pages.NOTIFICATIONS.path)}
              >
                Go Back
              </Button>
              <Card size='small'>
                <NotificationDetail
                  message={selectedMessage}
                  onDelete={onDeleteMessage}
                  onAcknowledge={onAcknowledgeMessage}
                />
              </Card>
            </Space>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default memo(Notifications);
