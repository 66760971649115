import React from 'react';
import { Router } from '@reach/router';
import { Main } from 'components/layout';
import { pages } from 'config/pages';
import Home from 'components/pages/Home';
import UnexpectedError from 'components/pages/UnexpectedError';
import TermsOfUse from 'components/pages/TermsOfUse';
import PrivacyPolicy from 'components/pages/PrivacyPolicy';
import ShiftPreference from 'components/pages/ShiftPreference';
import WorkHistory from 'components/pages/WorkHistory';
import Notifications from 'components/pages/Notifications';
import Schedules from 'components/pages/Schedule';
import Profile from 'components/pages/Profile';
import Support from 'components/pages/Support';

const Routes = () => (
  <Router>
    <Main path='/'>
      <Home path={pages.HOME.path} default />
      <Schedules path={pages.MY_SCHEDULE.path} />
      <ShiftPreference path={pages.SHIFT_PREFERENCE.path} />
      <WorkHistory path={pages.WORK_HISTORY.path} />
      <Notifications path={`${pages.NOTIFICATIONS.path}`} />
      <Support path={pages.SUPPORT.path} />
      <TermsOfUse path={pages.TERMS_OF_USE.path} />
      <Profile path={pages.PROFILE.path} />
      <PrivacyPolicy path={pages.PRIVACY_POLICY.path} />
      <UnexpectedError path={pages.UNEXPECTED_ERROR.path} />
    </Main>
  </Router>
);

export default Routes;
