import React, { memo } from 'react';
import { Menu } from 'antd';
import {
  HomeFilled,
  ClockCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  HistoryOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { strings, pages } from 'config';
import { hasValue } from '@rlean/utils';
import { msalInstance } from 'config/msal';
import { RLeanState } from '@rlean/core';
import * as entities from 'lib/entities';

interface MenuLayoutProps extends RouteComponentProps {
  mobileNavClick?: () => void;
}

const MenuNavigation = ({ mobileNavClick = () => {} }: MenuLayoutProps) => {
  const activePage = RLeanState<typeof entities>().select(
    ({ state }) => state.activePage
  );
  const navigate = useNavigate();

  const handleLogout = () => {
    msalInstance.logoutRedirect().then(() => {
      window.location.reload();
    });
  };

  const menuProps = {
    selectedKeys:
      hasValue(activePage, 'data.key') &&
      typeof activePage.data.key !== 'undefined'
        ? [activePage.data.key]
        : [],
    onClick: () => {},
  };

  if (mobileNavClick) {
    menuProps.onClick = mobileNavClick;
  }

  return (
    <Menu
      {...menuProps}
      items={[
        {
          key: pages.HOME.key,
          icon: <HomeFilled />,
          label: pages.HOME.title,
          onClick: () => navigate(pages.HOME.path),
        },
        {
          key: pages.MY_SCHEDULE.key,
          icon: <ClockCircleOutlined />,
          label: pages.MY_SCHEDULE.title,
          onClick: () => navigate(pages.MY_SCHEDULE.path),
        },
        {
          key: pages.PROFILE.key,
          icon: <UserOutlined />,
          label: pages.PROFILE.title,
          onClick: () => navigate(pages.PROFILE.path),
        },
        {
          key: pages.SHIFT_PREFERENCE.key,
          icon: <CalendarOutlined />,
          label: pages.SHIFT_PREFERENCE.title,
          onClick: () => navigate(pages.SHIFT_PREFERENCE.path),
        },
        {
          key: pages.WORK_HISTORY.key,
          icon: <HistoryOutlined />,
          label: pages.WORK_HISTORY.title,
          onClick: () => navigate(pages.WORK_HISTORY.path),
        },
        {
          key: pages.NOTIFICATIONS.key,
          icon: <MailOutlined />,
          label: pages.NOTIFICATIONS.title,
          onClick: () => navigate(pages.NOTIFICATIONS.path),
        },
        {
          key: pages.SUPPORT.key,
          icon: <QuestionCircleOutlined />,
          label: pages.SUPPORT.title,
          onClick: () => navigate(pages.SUPPORT.path),
        },
        { key: 'divider', type: 'divider' },
        {
          key: 'signOut',
          icon: <LogoutOutlined />,
          label: strings.actions.signOut,
          onClick: handleLogout,
        },
      ]}
      mode='inline'
    />
  );
};

export default memo(MenuNavigation);
