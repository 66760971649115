import { define } from '@rlean/core';

export type ContactUsType = {
  userObjectId: number;
  requestTypeId: number;
  message: string;
};

const ContactUs = define<ContactUsType>('contactUs', {
  initialState: {},
  nullableParams: true,
  postURL: '/ContactUs',
});

export default ContactUs;
