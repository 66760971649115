import React, { memo } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { Link, RouteComponentProps } from '@reach/router';
import {
  FacebookFilled,
  TwitterOutlined,
  LinkedinFilled,
} from '@ant-design/icons';
import { strings, pages } from 'config';
import MenuNavigation from './MenuNavigation';
import logoColor from 'assets/SQ-logo-color.png';
import { navigate } from '@reach/router';

interface MenuLayoutProps extends RouteComponentProps {
  mobileNavClick?: () => void;
}

const MenuLayout = ({ mobileNavClick = () => {} }: MenuLayoutProps) => {
  const { Header, Content, Footer } = Layout;

  const goToHome = () => {
    navigate(pages.HOME.path);
  };

  return (
    <Layout>
      <Header>
        <img
          style={{ width: '70%', cursor: 'pointer' }}
          src={logoColor}
          alt='Stratosphere Quality logo'
          onClick={goToHome}
        />
      </Header>
      <Content>
        <MenuNavigation mobileNavClick={mobileNavClick} />
      </Content>
      <Footer>
        <Card>
          <Row
            // type='flex'
            justify='space-around'
            className='sidebar-social-icons'
          >
            <Col>
              <a
                href='https://www.facebook.com/Stratosphere-Quality-LLC-468960463193346/'
                target='_blank'
                className='link-small'
                rel='noopener noreferrer'
              >
                <i className='facebook-icon' />
                <FacebookFilled />
              </a>
            </Col>
            <Col>
              <a
                href='https://www.linkedin.com/company/stratosphere-quality'
                className='link-small'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='linkedin-icon' />
                <LinkedinFilled />
              </a>
            </Col>
            <Col>
              <a
                href='http://twitter.com/improveproducts'
                className='link-small'
                target='_blank'
                rel='noopener noreferrer'
              >
                <TwitterOutlined />
              </a>
            </Col>
          </Row>
          <Row
            // type='flex'
            justify='space-between'
            className='terms'
          >
            <Link className='link-small' to={pages.TERMS_OF_USE.path}>
              {strings.termsOfUse.title}
            </Link>
            <Link className='link-small' to={pages.PRIVACY_POLICY.path}>
              {strings.privacyPolicy.title}
            </Link>
          </Row>
          <Row className='copyright'>
            © Stratosphere Quality {new Date().getFullYear()}{' '}
            {strings.allRightsReserved}.
          </Row>
        </Card>
      </Footer>
    </Layout>
  );
};

export default memo(MenuLayout);
