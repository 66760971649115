import React, { memo, useEffect, useState } from 'react';
import NotificationListItem from './NotificationListItem';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'antd';
import { EmployeePortalMessagesType } from 'lib/entities/EmployeePortalMessages';

type NotificationsListProps = {
  notifications: EmployeePortalMessagesType[];
  onClickUnreadMessage: Function;
  selectedMessageId?: number;
};

const NotificationsList = ({
  notifications = [],
  onClickUnreadMessage,
  selectedMessageId,
}: NotificationsListProps) => {
  const [loadedNotifications, setLoadedNotifications] = useState<
    EmployeePortalMessagesType[]
  >([]);
  const [customPage, setCustomPage] = useState(0);

  useEffect(() => {
    setLoadedNotifications([]);
    setCustomPage(0);
  }, [notifications]);

  const handleInfiniteOnLoad = () => {
    setLoadedNotifications(
      loadedNotifications.concat(notifications[customPage])
    );
    setCustomPage(customPage + 1);
  };

  return (
    <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 240px)' }}>
      <InfiniteScroll
        initialLoad
        pageStart={-1}
        loadMore={handleInfiniteOnLoad}
        hasMore={notifications.length > loadedNotifications.length}
        useWindow={false}
      >
        <List
          className='notifications-list'
          itemLayout='vertical'
          style={{ width: '100%' }}
          dataSource={loadedNotifications}
          size='small'
          bordered
          renderItem={(item) => (
            <NotificationListItem
              item={item}
              selected={item.id === selectedMessageId}
              update={onClickUnreadMessage}
              data-testid={`notification-list-item-${item.id}`}
            />
          )}
          data-testid='notifications-list'
        />
      </InfiniteScroll>
    </div>
  );
};

export default memo(NotificationsList);
