import { define } from '@rlean/core';

export type WorkHistoryDetail = {
  id: string;
  employeeId: number;
  weekStartDate: Date;
  calendarDate: Date;
  weekdayName: string;
  shiftDate: Date;
  shift: number;
  project: number;
  activity: string;
  startTime: string;
  endTime: string;
  duration_Minutes: number;
  supervisor: string;
  supervisorEmail: string;
};

const WorkHistoryDetails = define<WorkHistoryDetail[]>('workHistoryDetails', {
  nullableParams: false,
  getURL: '/WorkHistoryDetails',
});

export default WorkHistoryDetails;
