import React, { memo } from 'react';
import { MailOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { pages } from 'config';
import { List, Space, Tag } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';
import { formatDate } from 'lib/helpers';

type NotificationItemProps = {
  item: any;
  update: any;
  selected: any;
};

const NotificationItem = ({
  item,
  update = null,
  selected = false,
}: NotificationItemProps) => {
  const goToDetail = () => {
    if (update) {
      update(item.id);
    }
    navigate(`${pages.NOTIFICATIONS.path}?id=${item.id}`);
  };

  const { delivered } = item;

  return (
    <List.Item
      key={item.id}
      className={`${!delivered ? 'unread' : ''} ${
        selected ? 'selected' : ''
      } notification-list-item`}
      extra={
        item.priorityInd ? (
          <ExclamationOutlined style={{ color: 'red' }} />
        ) : null
      }
    >
      <div
        onClick={goToDetail}
        data-testid={`notification-list-item-${item.id}`}
      >
        <List.Item.Meta
          avatar={delivered ? <CheckSquareOutlined /> : <MailOutlined />}
          style={{ cursor: 'pointer' }}
          title={
            <Space>
              {item.subject}
              {item.acknowledged && <Tag color='#b2bb1c'>Acknowledged</Tag>}
            </Space>
          }
          description={`${item.senderFirstName} ${
            item.senderLastName
          } - ${formatDate(item.addedAt)}`}
        />
      </div>
      {`${item.message.substr(0, 70)}...`}
    </List.Item>
  );
};

export default memo(NotificationItem);
